import { SearchRequirement } from "./types";
import {DotaActionName} from "./DotaActionsEnum";


export const createDefaultSearchRequirement = (): SearchRequirement => {
    return {
        hero: "Any hero",
        action: undefined,
        startTime: 0,
        endTime: 0
    }
}

export const shouldRenderThirdColumn = (dotaActionType: keyof typeof DotaActionName | undefined) => {
    return !(dotaActionType === undefined
        || DotaActionName[dotaActionType] === DotaActionName.BuysBackAction
        || DotaActionName[dotaActionType] === DotaActionName.GetsDoubleKillAction
        || DotaActionName[dotaActionType] === DotaActionName.GetsRampageAction
        || DotaActionName[dotaActionType] === DotaActionName.GetsTripleKillAction
        || DotaActionName[dotaActionType] === DotaActionName.GetsUltraKillAction);
}

export const timeShouldBeMoment = (dotaActionType: keyof typeof DotaActionName | undefined) => {
    return dotaActionType && (DotaActionName[dotaActionType] === DotaActionName.HasLessGoldAction
        || DotaActionName[dotaActionType] === DotaActionName.HasLessExpAction
        || DotaActionName[dotaActionType] === DotaActionName.HasLessKillsAction
        || DotaActionName[dotaActionType] === DotaActionName.HasMoreGoldAction
        || DotaActionName[dotaActionType] === DotaActionName.HasMoreExpAction
        || DotaActionName[dotaActionType] === DotaActionName.HasMoreKillsAction
    )
}

export const shouldRenderAnotherHero = (dotaActionType: keyof typeof DotaActionName | undefined) => {
    return dotaActionType && (DotaActionName[dotaActionType] === DotaActionName.DiesToEnemyAction
        || DotaActionName[dotaActionType] === DotaActionName.KillsEnemyAction)
}

export const shouldRenderItem = (dotaActionType: keyof typeof DotaActionName | undefined) => {
    return dotaActionType && (DotaActionName[dotaActionType] === DotaActionName.BuysItemAction
        || DotaActionName[dotaActionType] === DotaActionName.UsesItemAction)
}

export const shouldRenderNumericValueInput = (dotaActionType: keyof typeof DotaActionName | undefined) => {
    return dotaActionType && (DotaActionName[dotaActionType] === DotaActionName.HasLessGoldAction
        || DotaActionName[dotaActionType] === DotaActionName.HasLessExpAction
        || DotaActionName[dotaActionType] === DotaActionName.HasLessKillsAction
        || DotaActionName[dotaActionType] === DotaActionName.HasMoreGoldAction
        || DotaActionName[dotaActionType] === DotaActionName.HasMoreExpAction
        || DotaActionName[dotaActionType] === DotaActionName.HasMoreKillsAction
    )
}

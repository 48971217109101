import SearchRequirementComponent from "./SearchRequirementComponent";
import {useEffect, useState} from "react";
import {
    createDefaultSearchRequirement,
    shouldRenderAnotherHero,
    shouldRenderItem,
    shouldRenderNumericValueInput
} from "./commons";
import {SearchRequirement} from "./types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import {DotaActionName} from "./DotaActionsEnum";
import ReactGA from 'react-ga4';

const NewQueryComponent = () => {

    const [searchRequirements, setSearchRequirements] = useState<SearchRequirement[]>([createDefaultSearchRequirement()]);
    const [heroes, setHeroes] = useState<string[]>([]);
    const [items, setItems] = useState<string[]>([]);
    const [multipleAnyHeroRefersToSameHero, setMultipleAnyHeroRefersToSameHero] = useState<boolean>(false);
    const [priorityCode, setPriorityCode] = useState<string | undefined>();
    const [showPriorityCode, setShowPriorityCode] = useState<boolean>(false);
    const navigate = useNavigate()

    ReactGA.send({
     hitType: "pageview",
     page: "/",
     title: "New Query"
    });

    useEffect(() => {
        fetch('/api/heroes')
            .then(response => response.json())
            .then(data => {
                setHeroes(data);
            })
    }, []);
    useEffect(() => {
        fetch('/api/items')
            .then(response => response.json())
            .then(data => {
                setItems(data);
            })
    }, []);

    const modifySearchRequirement = (searchRequirementIdx: number): (updatedSearchReq: SearchRequirement) => void => {
        const updateFunction: (updatedSearchReq: SearchRequirement) => void = (updatedSearchRequirement: SearchRequirement) => {
            const newSearchRequirements = [...searchRequirements];
            newSearchRequirements[searchRequirementIdx] = updatedSearchRequirement;
            setSearchRequirements(newSearchRequirements);
        }
        return updateFunction;
    }

    const deleteSearchRequirement = (searchRequirementIdx: number): () => void => {
        const updateFunction: () => void = () => {
            const newSearchRequirements = [...searchRequirements];
            newSearchRequirements.splice(searchRequirementIdx, searchRequirementIdx);
            setSearchRequirements(newSearchRequirements);
        }
        return updateFunction;
    }

    const addNewSearchRequirement = (): void => {
        const newSearchRequirements = [...searchRequirements];
        newSearchRequirements.push(createDefaultSearchRequirement());
        setSearchRequirements(newSearchRequirements);
    }

    const validateQuery = (): boolean => {
        return searchRequirements.every((requirement) => {
            if (requirement.hero === "Select a hero") {
                alert("Hero not selected in one of the requirements")
                return false;
            }
            if (requirement.action === undefined) {
                alert("Action not selected in one of the requirements")
                return false;
            }
            if (requirement.endTime === 0) {
                alert("Game time not selected or the end time is 00:00:00 in one of the requirements")
                return false;
            }
            if (shouldRenderNumericValueInput(requirement.action) && requirement.value === undefined) {
                alert("Value not selected in one of the requirements")
                return false;
            }
            if (shouldRenderAnotherHero(requirement.action) && requirement.recipient === undefined) {
                alert("Recipient hero not selected in one of the requirements")
                return false;
            }
            if (shouldRenderItem(requirement.action) && requirement.recipient === undefined) {
                alert("Item not selected in one of the requirements")
                return false;
            }
            return true;
        })
    }

    const validateQuerySendAndRedirect = () => {
        if (!validateQuery()) {
            return;
        }
        fetch(`/api/query`, {
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({searchRequirements: searchRequirements, multipleAnyHeroRefersToSameHero: multipleAnyHeroRefersToSameHero, priorityCode: priorityCode})
        }).then((response) => {
            if (response.ok) {
                return response.text();
            }
            throw new Error(`Error creating query, status: ${response.status}`);
        }).then((uuid) => {
            navigate(`/query/${uuid}`);
        }).catch((e) => {
            alert("Unable to create a query for some reason")
        })
    }

    return (
        <>
            <div className="descriptionWrapper mt-5 mb-5">
                <h1 className="text-center header-font" style={{fontFamily: 'Merienda', fontWeight: 800}}>DOTA REPLAY
                    FINDER</h1>
                <p className="text-center" style={{fontSize: "1.17em", paddingTop: "0px", marginBottom: "2px"}}>Search high MMR and pro
                    matches in which specific events happened</p>
                <p className="text-center" style={{fontSize: "1.09.em", paddingTop: "0px", marginTop: "0px"}}><i>Games from last 7 days</i></p>
            </div>
            <div className="container">
                <hr/>
                <p className="text-center" style={{fontSize: "1.95em", fontWeight: "bolder"}}>List your search
                    criteria</p>
                {searchRequirements.map((searchRequirement, index) => (
                    <>
                        <SearchRequirementComponent
                            searchRequirement={searchRequirement}
                            changeSearchRequirement={modifySearchRequirement(index)}
                            deleteSearchRequirement={deleteSearchRequirement(index)}
                            heroes={heroes}
                            items={items}
                            key={`search_requirement_${index}`}
                        />
                    </>
                ))}
                <div>
                    <div className="form-group">
                        <div className="dropdown">
                            <button className="btn btn-warning" onClick={addNewSearchRequirement}>
                                <FontAwesomeIcon className="fa-solid" icon={faPlus} size="lg"/>
                                {` Add another requirement`}
                            </button>
                        </div>
                    </div>
                </div>
                {searchRequirements.map(searchRequirement => searchRequirement?.hero).filter(heroName => heroName === 'Any hero').length >= 2 &&
                    <div className="form-check mt-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            onChange={() => {
                                setMultipleAnyHeroRefersToSameHero(!multipleAnyHeroRefersToSameHero);
                            }}
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Multiple requirements regarding <b>Any hero</b> should refer to the same hero
                        </label>
                    </div>
                }
                <hr/>
                <div className="form-check mt-2">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="promocodeCheck"
                        onChange={() => {
                            setShowPriorityCode(!showPriorityCode);
                        }}
                    />
                    <label className="form-check-label" htmlFor="promocodeCheck">
                        I have a priority processing code
                    </label>
                    {showPriorityCode &&
                        <>
                            <br/>
                            <input
                                type="text"
                                className="form-control mt-3"
                                placeholder="Enter your priority Code"
                                onChange={(e) => {
                                    setPriorityCode(e.target.value);
                                }}
                            />
                        </>
                    }
                </div>
            </div>
            <div className="text-center my-5">
                <button type="button" className="btn btn-warning btn-lg">
                    <div onClick={validateQuerySendAndRedirect}>
                        <FontAwesomeIcon icon={faSearch} size="lg"/>
                        {` Search for such replays`}
                    </div>
                </button>
            </div>
        </>
    )
}

export default NewQueryComponent;

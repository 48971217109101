import ReactGA from 'react-ga4';

const IntroComponent = () => {

    ReactGA.send({
     hitType: "pageview",
     page: "/intro",
     title: "Intro"
    });

    return (
        <>
            <div className="descriptionWrapper mt-5 mb-5">
                <h1 className="text-center header-font" style={{fontFamily: 'Merienda', fontWeight: 800}}>DOTA REPLAY FINDER</h1>
                <div className="container">
                    <p className="text-center mt-5" style={{fontSize: "1.17em", paddingTop: "0px"}}>
                        Search high MMR and pro matches in which specific events happened by constructing search
                        requirements.
                        Each search requirements consist of a hero, time, action and potentially some other
                        clarification data.
                        <br/>
                        Currently the replay pool consists only of PGL Wallachia games
                        <br/>
                        One example is: <i><b>Give me every repay in which Luna gets a rampage at any point throughout
                        the entire
                        game. </b></i>
                        <br/>
                        Another one is: <i><b>Give me every repay in which any hero has more than 5k gold at minute
                        8</b></i>
                        <br/>
                        A third one is: <i><b>Give me every repay in which void spirit buys dagon between minutes 15 and
                        25</b></i>
                        <br/>
                        You can add multiple requirements in a single query meaning that only replays in which all of
                        your requirements are met will be returned.
                        The replay pool currently includes about 100 games mostly from PGL Wallachia and high MMR pubs.
                        <br/>
                        <b>Below are some FAQ:</b>
                    </p>
                    <br/>

                    <div className="accordion accordion-requirement">
                        <div className="accordion-item my-3 ">
                            <h2 className="accordion-header ">
                                <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                        data-bs-target={`#collapse-q1`} aria-expanded="true"
                                        aria-controls={`collapse-requirements`}>
                                    <h5>Why is it taking so long?</h5>
                                </button>
                            </h2>
                            <div id={`collapse-q1`} className="accordion-collapse collapse show">
                                <div className="accordion-body">
                                    <div>
                                        For each query, each relevant replay is being processed. In case this takes some
                                        time, you can copy the query URL and go back to it when it's ready.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion accordion-requirement">
                        <div className="accordion-item my-3 ">
                            <h2 className="accordion-header ">
                                <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                        data-bs-target={`#collapse-q4`} aria-expanded="true"
                                        aria-controls={`collapse-requirements`}>
                                    <h5>Who can benefit from that tool?</h5>
                                </button>
                            </h2>
                            <div id={`collapse-q4`} className="accordion-collapse collapse show">
                                <div className="accordion-body">
                                    <div>
                                        <b>First and foremost: content creators!</b> Instead of playing random rampages as the background of an analytical video, content creators can select proper video content to show.
                                        For example, a content creator is explaining how broken the physical damage build on Zeus is. Now they can query for games where Zeus buys Agh's shard before minute 15:10, buys Manta before 17:00 and gets more than 15 kills throughout the game. And their viewers can follow a game that is relevant to what the content creator is talking about.
                                        <br/>
                                        <b>Analysts for professional teams on the other hand</b>, can use this as a strong analytical tool. For example, they can query all games where Gyro has more than 13k gold at minute 15 in order to analyze farming patterns.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion accordion-requirement">
                        <div className="accordion-item my-3 ">
                            <h2 className="accordion-header ">
                                <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                        data-bs-target={`#collapse-q3`} aria-expanded="true"
                                        aria-controls={`collapse-requirements`}>
                                    <h5>Are these the only options?</h5>
                                </button>
                            </h2>
                            <div id={`collapse-q3`} className="accordion-collapse collapse show">
                                <div className="accordion-body">
                                    <div>
                                        For the time being, yes. Depending on the feedback from the community, more options could be added such as querying for Radiant or Dire gold, exp, kills advantage, camps stacked, damage done or damage received at particular timestamp. Also, querying for teams and players might be added.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion accordion-requirement">
                        <div className="accordion-item my-3 ">
                            <h2 className="accordion-header ">
                                <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                        data-bs-target={`#collapse-q2`} aria-expanded="true"
                                        aria-controls={`collapse-requirements`}>
                                    <h5>What does <i>Multiple requirements regarding Any hero should refer to the same hero</i> mean?</h5>
                                </button>
                            </h2>
                            <div id={`collapse-q2`} className="accordion-collapse collapse show">
                                <div className="accordion-body">
                                    <div>
                                        Suppose a query has two requirements: Any hero gets a rampage and Any hero buys Divine Rapier.
                                        If there is a replay in which Sven gets a rampage and Medusa buys Divine Rapier, in case the checkbox is checked, the replay would not be considered to satisfy the requirements
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion accordion-requirement">
                        <div className="accordion-item my-3 ">
                            <h2 className="accordion-header ">
                                <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                        data-bs-target={`#collapse-q5`} aria-expanded="true"
                                        aria-controls={`collapse-requirements`}>
                                    <h5>Is the finder querying all games in the world?</h5>
                                </button>
                            </h2>
                            <div id={`collapse-q5`} className="accordion-collapse collapse show">
                                <div className="accordion-body">
                                    <div>
                                        No. Currently only the games from the recent PGL Wallachia tournaments are being queried. All of them. In case of a positive feedback from the community, I will start adding recent very high MMR matches and all pro games.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion accordion-requirement">
                        <div className="accordion-item my-3 ">
                            <h2 className="accordion-header ">
                                <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                        data-bs-target={`#collapse-q6`} aria-expanded="true"
                                        aria-controls={`collapse-requirements`}>
                                    <h5>What's new about this tool?</h5>
                                </button>
                            </h2>
                            <div id={`collapse-q6`} className="accordion-collapse collapse show">
                                <div className="accordion-body">
                                    <div>
                                        The flexibility in querying. You're not just getting raw stats without context, you can simply describe the type of game you want to find and the finder will find these for you if such exist. I envision this to be beneficial for analytical or content creation purposes.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IntroComponent;

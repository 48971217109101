import {GameTime} from "./types";
import {TimePickerType} from "./TimePickerType";

export interface TimePickerComponentProps {
    time: GameTime;
    setTime: (time: GameTime) => void;
    timePickerType: TimePickerType;
}

const TimePickerComponent = ({time, setTime, timePickerType}: TimePickerComponentProps) => {
    const updateHourOfStartOrEndTime = (hours: number, startOrEndTime: GameTime, setStartOrEndTime: (time: GameTime) => void) => {
        const startOrEndTimeNew = {...startOrEndTime};
        startOrEndTimeNew.hours = hours;
        setStartOrEndTime(startOrEndTimeNew);
    }

    const updateMinutesOfStartOrEndTime = (minutes: number, startOrEndTime: GameTime, setStartOrEndTime: (time: GameTime) => void) => {
        const startOrEndTimeNew = {...startOrEndTime};
        startOrEndTimeNew.minutes = minutes;
        setStartOrEndTime(startOrEndTimeNew);
    }

    const updateSecondsOfStartOrEndTime = (seconds: number, startOrEndTime: GameTime, setStartOrEndTime: (time: GameTime) => void) => {
        const startOrEndTimeNew = {...startOrEndTime};
        startOrEndTimeNew.seconds = seconds;
        setStartOrEndTime(startOrEndTimeNew);
    }

    return (
        <div className="row my-4">
            <div className="col-sm-4">
                <label style={{visibility: "hidden"}}>Description</label>
                <div className="text-lg">{timePickerType.toString()}</div>
            </div>
            <div className="col-sm-2">
                <label>Hours</label>
                <select
                    className="form-control"
                    name="dropdown"
                    onChange={(e) => updateHourOfStartOrEndTime(Number(e.target.value), time, setTime)}
                    defaultValue={0}
                >
                    <option key="start_hours_0" value="0">00</option>
                    <option key="start_hours_1" value="1">01</option>
                </select>
            </div>
            <div className="col-sm-2">
                <label>Minutes</label>
                <select
                    className="form-control"
                    name="dropdown"
                    onChange={(e) => updateMinutesOfStartOrEndTime(Number(e.target.value), time, setTime)}
                    defaultValue={0}
                >
                    { Array.from({ length: 60 }, (_, i) => i).map((idx) => (
                        <option key={`start_minutes_${idx}`} value={idx}>{idx.toString().padStart(2, '0')}</option>
                    ))}
                </select>
            </div>
            <div className="col-sm-2">
                <label>Seconds</label>
                <select
                    className="form-control"
                    name="dropdown"
                    onChange={(e) => updateSecondsOfStartOrEndTime(Number(e.target.value), time, setTime)}
                    defaultValue={0}
                >
                    { Array.from({ length: 60 }, (_, i) => i).map((idx) => (
                        <option key={`start_seconds_${idx}`} value={idx}>{idx.toString().padStart(2, '0')}</option>
                    ))}
                </select>
            </div>
        </div>

    )
}

export default TimePickerComponent;

import {GameTime, SearchRequirement} from "./types";
import {useEffect, useState} from "react";
import {DotaActionName} from "./DotaActionsEnum";
import TimePickerComponent from "./TimePickerComponent";
import {TimePickerType} from "./TimePickerType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons/faTrashCan";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {
    shouldRenderAnotherHero,
    shouldRenderItem,
    shouldRenderNumericValueInput,
    shouldRenderThirdColumn,
    timeShouldBeMoment
} from "./commons";

export interface SearchRequirementComponentData {
    searchRequirement: SearchRequirement;
    changeSearchRequirement: (updatedSearchReq: SearchRequirement) => void;
    deleteSearchRequirement: () => void;
    heroes: string[];
    items: string[];
}

const SearchRequirementComponent = (
    { searchRequirement, changeSearchRequirement, deleteSearchRequirement, heroes, items}: SearchRequirementComponentData
) => {

    const [startTime, setStartTime] = useState<GameTime>(({hours: 0, minutes: 0, seconds: 0}));
    const [endTime, setEndTime] = useState<GameTime>(({hours: 0, minutes: 0, seconds: 0}));
    const [timeSelected, setTimeSelected] = useState<boolean>(false);
    const [timeOfEntireGame, setQueryIsForEntireGame] = useState<boolean>(false);
    const [dotaActionType, setDotaActionType] = useState<keyof typeof DotaActionName | undefined>(undefined);
    const [timeModalIsOpen, setTimeModalIsOpen] = useState<boolean>(false);
    const [heroFilter, setHeroFilter] = useState<string>('');
    const [additionalHeroFilter, setAdditionalheroFilter] = useState<string>('');
    const [itemFilter, setItemFilter] = useState<string>('');

    useEffect(() => {
        selectTheEntireGameTimeInterval();
    }, []);

    useEffect(() => {
        if (!timeShouldBeMoment(dotaActionType)) {
            selectTheEntireGameTimeInterval();
        } else {
            setStartTime(({hours: 0, minutes: 0, seconds: 0}));
            setEndTime(({hours: 0, minutes: 0, seconds: 0}));
            setTimeSelected(false);
            setTimeModalIsOpen(false);
        }
    }, [dotaActionType])

    useEffect(() => {
        updateTimeDataInParentComponent();
    }, [startTime, endTime])

    const setStartAndEndTime = (gameTime: GameTime) => {
        setStartTime(gameTime);
        setEndTime(gameTime);
    }

    const generateSelectTimeText = (): string => {
        if (!timeSelected) {
            return "Select event time"
        }
        if (timeOfEntireGame) {
            return "The entire game";
        }
        if (timeShouldBeMoment(dotaActionType)) {
            return `${startTime.hours.toString().padStart(2, '0')}:${startTime.minutes.toString().padStart(2, '0')}:${startTime.seconds.toString().padStart(2, '0')}`;
        }
        return `${startTime.hours.toString().padStart(2, '0')}:${startTime.minutes.toString().padStart(2, '0')}:${startTime.seconds.toString().padStart(2, '0')} - ${endTime.hours.toString().padStart(2, '0')}:${endTime.minutes.toString().padStart(2, '0')}:${endTime.seconds.toString().padStart(2, '0')}`
    }

    const endTimeAfterStartTime = () => {
        return (startTime.hours * 3600 + startTime.minutes * 60 + startTime.seconds) <= (endTime.hours * 3600 + endTime.minutes * 60 + endTime.seconds);
    }

    const changePerformingHero = (hero: string | null) => {
        if (hero) {
            const changedSearchRequirement = {...searchRequirement};
            changedSearchRequirement.hero = hero;
            changeSearchRequirement(changedSearchRequirement);
        }
    }

    const selectTheEntireGameTimeInterval = () => {
        setQueryIsForEntireGame(true);
        setStartTime(({hours: 0, minutes: 0, seconds: 0}));
        setEndTime(({hours: 2, minutes: 0, seconds: 0}));
        if (endTimeAfterStartTime()) {
            setTimeSelected(true);
            updateTimeDataInParentComponent();
        } else {
            alert("Start time must be lower than end time")
            setTimeSelected(false)
        }
        setTimeModalIsOpen(false);
    }

    const changeRecipient = (recipient: string | null | undefined) => {
        if (recipient) {
            const changedSearchRequirement = {...searchRequirement};
            changedSearchRequirement.recipient = recipient;
            changeSearchRequirement(changedSearchRequirement);
        }
    }

    const changeValue = (value: string | null | undefined) => {
        console.log(value)
        if (value) {
            const changedSearchRequirement = {...searchRequirement};
            changedSearchRequirement.value = value;
            changeSearchRequirement(changedSearchRequirement);
        }
    }

    const changeAction = (action: keyof typeof DotaActionName) => {
        if (action) {
            const changedSearchRequirement = {...searchRequirement};
            changedSearchRequirement.action = action;
            changeSearchRequirement(changedSearchRequirement);
        }
    }

    const updateTimeDataInParentComponent = () => {
        const changedSearchRequirement = {...searchRequirement};
        changedSearchRequirement.startTime = startTime.hours * 3600 + startTime.minutes * 60 + startTime.seconds;
        changedSearchRequirement.endTime = endTime.hours * 3600 + endTime.minutes * 60 + endTime.seconds;
        changeSearchRequirement(changedSearchRequirement);
    }

    return (
        <>
            <div className="row my-4">
                <div className="col-md-2 ">
                    <div className="form-group">
                        <label htmlFor="performingHeroDropdown">Hero:</label>
                        <div className="dropdown">
                            <button
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="performingHeroDropdown"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                    onClick={() => setHeroFilter('')}
                            >
                                {searchRequirement.hero}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="performingHeroDropdown">
                                <li key="search">
                                    <div className="row container-fluid">
                                        <div className="col-4 mt-2">
                                            <FontAwesomeIcon icon={faSearch} size="sm"/>
                                        </div>
                                        <div className="col-8 mx-0 px-0">
                                            <input className="form-control" value={heroFilter}
                                                   onChange={(e) => setHeroFilter(e.target.value)}/>
                                        </div>
                                    </div>
                                </li>
                                <li key={`performer_any_hero`}>
                                    <p
                                        className="dropdown-item"
                                        style={{cursor: "grabbing"}}
                                        onClick={(e) => changePerformingHero((e.target as HTMLLIElement).textContent)}
                                    >
                                        <b>{'Any hero'}</b>
                                    </p>
                                </li>
                                {heroes.filter((h) => h.toLowerCase().startsWith(heroFilter.toLowerCase()))
                                    .filter(h => h !== 'Any hero')
                                    .map((hero) => (
                                    <li key={`performer_${hero}`}>
                                        <p
                                            className="dropdown-item"
                                            style={{cursor: "grabbing"}}
                                            onClick={(e) => changePerformingHero((e.target as HTMLLIElement).textContent)}
                                        >
                                            {hero}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="form-group">
                        <label htmlFor="actionDropdown">Action:</label>
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="actionDropdown"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                            {dotaActionType ? DotaActionName[dotaActionType] : "Select an action"}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="actionDropdown">
                                {Object.keys(DotaActionName).map((action) => (
                                    <li key={action}>
                                        <p
                                            className="dropdown-item"
                                            style={{cursor: "grabbing"}}
                                            onClick={(e) => {
                                                const dotaActionLocalised = (e.target as HTMLOptionElement).textContent;
                                                const actionEnumKey: string = Object.keys(DotaActionName).find(a => DotaActionName[a as keyof typeof DotaActionName] === dotaActionLocalised) || DotaActionName.KillsEnemyAction;
                                                changeAction(actionEnumKey as keyof typeof DotaActionName);
                                                setDotaActionType(actionEnumKey as keyof typeof DotaActionName);
                                            }}
                                        >
                                            {DotaActionName[action as keyof typeof DotaActionName]}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 ">
                    <div className="form-group">
                        <label htmlFor="timeDropdown">Game time:</label>
                        <div className="dropdown">
                            <ul className="dropdown-menu" aria-labelledby="timeDropdown">
                                {!timeShouldBeMoment(dotaActionType) &&
                                    <li key="entire-game">
                                    <p
                                        className="dropdown-item"
                                        style={{cursor: "grabbing"}}
                                        onClick={() => {
                                            selectTheEntireGameTimeInterval();
                                        }}
                                    >
                                        <b>{'The entire game'}</b>
                                    </p>
                                </li>
                                }
                                <li>
                                    <p
                                        className="dropdown-item"
                                        style={{cursor: "grabbing"}}
                                        onClick={() => {
                                            setStartTime(({hours: 0, minutes: 0, seconds: 0}));
                                            setEndTime(({hours: 0, minutes: 0, seconds: 0}));
                                            setTimeSelected(false);
                                            setQueryIsForEntireGame(false);
                                            setTimeModalIsOpen(true);
                                        }}
                                    >
                                        {`Specify time`}
                                    </p>
                                </li>
                            </ul>
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="timeDropdown"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                            {generateSelectTimeText()}
                            </button>


                            <div
                                className="modal fade show"
                                aria-modal={true}
                                tabIndex={-1}
                                style={timeModalIsOpen ? {display: "block"} : {display: "none"}}
                            >
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">{`Select a time ${timeShouldBeMoment(dotaActionType) ? 'moment' : 'frame'}`}</h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                aria-label="Close"
                                                onClick={() => setTimeModalIsOpen(false)}
                                            />
                                        </div>
                                        <div className="modal-body">
                                            {timeShouldBeMoment(dotaActionType) ?
                                                (
                                                    <TimePickerComponent
                                                        time={startTime}
                                                        setTime={setStartAndEndTime}
                                                        timePickerType={TimePickerType.TimeMoment}
                                                    />
                                                )
                                                :
                                                (
                                                    <>
                                                        <TimePickerComponent
                                                            time={startTime}
                                                            setTime={setStartTime}
                                                            timePickerType={TimePickerType.StartTime}
                                                        />
                                                        <TimePickerComponent
                                                            time={endTime}
                                                            setTime={setEndTime}
                                                            timePickerType={TimePickerType.EndTime}
                                                        />
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-bs-dismiss="modal"
                                                onClick={() => {
                                                    if (endTimeAfterStartTime()) {
                                                        setTimeSelected(true)
                                                        updateTimeDataInParentComponent();
                                                    } else {
                                                        alert("Start time must be lower than end time")
                                                        setTimeSelected(false)
                                                    }
                                                    setTimeModalIsOpen(false);
                                                }}
                                            >
                                                Set time
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-2">
                    {shouldRenderThirdColumn(dotaActionType) &&
                        <div className="form-group">
                            {shouldRenderAnotherHero(dotaActionType) &&
                                <>
                                    <label htmlFor="recipientHeroDropdown">Hero:</label>
                                    <div className="dropdown">
                                        <button className="btn btn-secondary dropdown-toggle" type="button"
                                                id="recipientHeroDropdown"
                                                data-bs-toggle="dropdown" aria-expanded="false"
                                                onClick={() => setAdditionalheroFilter('')}
                                        >
                                            {searchRequirement.recipient ? searchRequirement.recipient : "Select a hero"}
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li key="search">
                                                <div className="row container-fluid">
                                                    <div className="col-4 mt-2">
                                                    <FontAwesomeIcon icon={faSearch} size="sm"/>
                                                    </div>
                                                    <div className="col-8 mx-0 px-0">
                                                        <input className="form-control" value={additionalHeroFilter}
                                                               onChange={(e) => setAdditionalheroFilter(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </li>
                                            <li key={`additional_hero_any`}>
                                                <p
                                                    className="dropdown-item"
                                                    style={{cursor: "grabbing"}}
                                                    onClick={(e) => changeRecipient((e.target as HTMLLIElement).textContent)}
                                                >
                                                    <b>{`Any hero`}</b>
                                                </p>
                                            </li>
                                            {heroes.filter((h) => h.toLowerCase().startsWith(additionalHeroFilter.toLowerCase()))
                                                .filter(h => h !== 'Any hero')
                                                .map((hero) => (
                                                    <li key={`additional_hero_${hero}`}>
                                                        <p
                                                            className="dropdown-item"
                                                            style={{cursor: "grabbing"}}
                                                            onClick={(e) => changeRecipient((e.target as HTMLLIElement).textContent)}
                                                        >
                                                            {hero}
                                                        </p>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </>
                            }
                            {shouldRenderItem(dotaActionType) &&
                                <>
                                    <label htmlFor="itemDropdown">Item:</label>
                                    <div className="dropdown">
                                        <button className="btn btn-secondary dropdown-toggle" type="button"
                                                id="itemDropdown"
                                                data-bs-toggle="dropdown" aria-expanded="false"
                                                onClick={() => setItemFilter('')}
                                        >
                                        {searchRequirement.recipient ? searchRequirement.recipient : "Select an item"}
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li key="search">
                                                <div className="row container-fluid">
                                                    <div className="col-4 mt-2">
                                                        <FontAwesomeIcon icon={faSearch} size="sm"/>
                                                    </div>
                                                    <div className="col-8 mx-0 px-0">
                                                        <input className="form-control" value={itemFilter}
                                                               onChange={(e) => setItemFilter(e.target.value)}/>
                                                    </div>
                                                </div>
                                            </li>
                                            {[...new Set(items)]
                                                .filter((i) => i.toLowerCase().startsWith(itemFilter.toLowerCase()))
                                                .map((item) => (
                                                    <li key={item}>
                                                        <p
                                                            className="dropdown-item"
                                                            style={{cursor: "grabbing"}}
                                                            onClick={(e) => changeRecipient((e.target as HTMLLIElement).textContent)}
                                                        >
                                                            {item}
                                                        </p>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </>
                            }
                            {shouldRenderNumericValueInput(dotaActionType) &&
                                <>
                                    <label>Value:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        onChange={(e) => {
                                            changeValue((e.target as HTMLInputElement).value?.toString())
                                        }}
                                    />
                                </>
                            }
                        </div>
                    }
                </div>

                <div className="col-md-1 ">
                    <div className="form-group" style={{cursor: "pointer"}}>
                        <label style={{visibility: "hidden"}}>Requirement:</label>
                        <div className="dropdown" >
                            <FontAwesomeIcon icon={faTrashCan} color="red" size="xl" onClick={deleteSearchRequirement}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchRequirementComponent;

import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import {Route, Routes, useNavigate} from 'react-router-dom';
import NewQueryComponent from "./search-query/NewQueryComponent";
import QueryResultComponent from "./search-query/QueryResultComponent";
import React, {useState} from "react";
import ReactGA from 'react-ga4';
import IntroComponent from "./search-query/IntroComponent";

function App() {
  const navigate = useNavigate();
  const [uuid, setUuid] = useState<string | undefined>()

  ReactGA.initialize('G-F7HPJSKHGP',
       {
            gaOptions: {
              debug: true,
              titleCase: false,
              siteSpeedSampleRate: 100
            }
       }
   );

  return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: '#A6B8C9'}}>
          <div className="container-fluid">
            <a className="navbar-brand general-header" href="/">Dota Replay Finder</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"/>
            </button>
            <br/>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/">New Query</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/intro">Get Started</a>
                </li>
              </ul>
              <form className="d-flex">
                <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search by query id"
                    aria-label="Search"
                    onChange={(e) => setUuid(e.target.value)}
                />
                <button
                    className="btn btn-outline-primary"
                    type="submit"
                    onClick={() => {
                      if (uuid !== undefined) {
                        navigate(`/query/${uuid}`);
                      }
                    }}
                  >
                  Search
                </button>
              </form>
            </div>
          </div>
        </nav>
        <Routes>
          <Route path="/" element={<NewQueryComponent />} />
          <Route path="/query/:queryId" element={<QueryResultComponent />} />
          <Route path="/intro" element={<IntroComponent />} />
        </Routes>
      </>
  );
}

export default App;

export enum DotaActionName {
    BuysBackAction="Buys Back",
    BuysItemAction="Buys Item",
    DiesToEnemyAction="Gets killed by",
    GetsDoubleKillAction="Gets a double kill",
    GetsRampageAction="Gets a rampage",
    GetsTripleKillAction="Gets a triple kill",
    GetsUltraKillAction="Gets an ultra kill",
    HasLessExpAction="Has less exp than",
    HasLessGoldAction="Has less gold than",
    HasLessKillsAction="Has less kills than",
    HasMoreExpAction="Has more exp than",
    HasMoreGoldAction="Has more gold than",
    HasMoreKillsAction="Has more kills than",
    KillsEnemyAction="Kills enemy",
    UsesItemAction="Uses item"
}
